.linkText {
  cursor: pointer;
}

.linkText:hover {
  text-decoration: underline;
}

/*
@-webkit-keyframes borderBlink {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}
 
@keyframes borderBlink {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

.borderBlink {
  border: 1px solid black;
  border-color: transparent;
}
.borderBlink:hover {
  -webkit-animation: borderBlink 1s step-end infinite;
  animation: borderBlink 1s step-end infinite;
} */
