.seTitle {
  color: black;
}

.nav {
  background-color: white;
}


.navRow {
    margin: 5px;
}

.loadingIconColor {
    color: #007bff;
}

.navRowTop {
    margin-top: 5px;
}

.navRowBottom {
    margin-top: 5px;
}

.navMarginLeft {
    margin-left: 5px;
}