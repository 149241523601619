.sortText {
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
}

.sortTypes {
  display: flex;
  width: 20%;
}
.sortOrder {
  display: flex;
  justify-content: flex-end;
  width: 80%;
}

.sortOrderClick:hover{
    cursor:pointer;
    text-decoration: underline;
  }