.itemDiv {
    border: 2px solid ;
    margin-bottom:15px;
    padding: 10px;
    border-radius: 25px;
    border-color: #6692b2;
}

.rowDiv {
    margin-bottom: 1px;
}